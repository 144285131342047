export class Pagination {
  total = null;
  currentPage = 1;
  perPage = 20;
  from = 1;
  to = 10
  tableData = []

  constructor(data) {
    Object.assign(this, data)
  }

  setTableData(data) {
    this.tableData = data;
    this.calculatefromTo();
  }

  calculatefromTo() {
    this.from = (this.perPage * this.currentPage) - this.perPage + 1;
    let less = this.perPage - this.tableData.length;
    this.to = ((this.perPage) * this.currentPage - less);
  }
}

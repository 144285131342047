<template>
  <b-container class="main-content-container px-4" fluid>
    <b-row class="page-header py-4" no-gutters>
      <b-col class="text-center text-sm-left mb-4 mb-sm-0" col sm="12">
        <h3 class="page-title">{{ 'Vin search rules' }}</h3>
        <span class="text-uppercase page-subtitle">{{ 'Vin search rules' }}</span>
      </b-col>
    </b-row>
    <b-card>
      <div class="pt-5 dataTables_wrapper VueTables no-shadow">
        <b-table small bordered hover class="dataTable" :items="pagination.tableData" :fields="fields">
          <template #cell(options)="{item}">
            <b-button-group class="d-flex justify-content-center" size="small">
              <b-button
                v-tippy="{ placement : 'top',  arrow: true }" :content="'Delete'"
                class="btn btn-primary"
                @click="deleteItem(item._id)">
                <i class="material-icons">delete</i>
              </b-button>
            </b-button-group>
          </template>
        </b-table>
      </div>
      <div class="d-flex justify-content-between">
        <p class="justify-content-start pl-4 pt-3">
          Showing {{ pagination.from }} to {{ pagination.to }} of
          {{ pagination.total }} records</p>
        <b-pagination
          class="justify-content-end no-shadow p-2"
          :value="pagination.currentPage"
          :per-page="pagination.perPage"
          :total-rows="pagination.total"
          @change="paginate"
        />
      </div>
    </b-card>
  </b-container>
</template>

<script>
import {Pagination} from "@/utils/pagination";
import {get, post} from "@/services/api";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "VinSearchRules",
  mixins: [show_alert_mixin],
  data: () => ({
    fields: [
      {key: '_id', label: 'ID'},
      {key: 'field', label: 'Field'},
      {key: 'result_value', label: 'PL Result Value'},
      {key: 'found_value', label: 'Value to find'},
      'options'
    ],
    pagination: new Pagination(),
  }),
  methods: {
    load() {
      this.$store.commit('loaderManager', true);
      get(`vin-search-rules?page=${this.pagination.currentPage}&limit=${this.pagination.perPage}`)
        .then(({data}) => {
          this.pagination.total = data.count;
          this.pagination.setTableData(data.data);
        }).finally(() => this.$store.commit('loaderManager', false))
    },
    paginate(page) {
      this.pagination.currentPage = page;
      this.load();
    },
    deleteItem(id) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Are you sure to delete this search rule?',
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async res => {
        if (res.isConfirmed) {
          this.$store.commit('loaderManager', true);
          post(`vin-search-rules/delete/${id}`).then(() => {
            this.showAlertNotification('Search rule deleted !')
          }).finally(() => {
            this.$store.commit('loaderManager', false);
            this.load();
          })
        }
      });

    }
  },
  mounted() {
    this.load()
  }
}
</script>

<style scoped>

</style>
